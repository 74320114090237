/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum DurationType {
    NONE = 'none',
    DAYS = 'days',
    WEEKS = 'weeks',
    MONTHS = 'months',
}
